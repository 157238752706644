.PaymentOptions tr.pad-top > td {
    padding-top: 1rem;
}

.PaymentOptions table.payment-options > tbody > tr > td:first-child {
    padding-right: 0.75rem;
}

.PaymentOptions .logo-container > img {
    margin: 0.25rem;
    max-height: 50px;
}