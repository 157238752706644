.ContactBar a {
  cursor: pointer;
  background-color: transparent;
  flex: 1;
  margin: 12px;
  text-decoration: none;
  text-align: center;
  max-width: 60px;
  padding: none;
}

.ContactBar a > img {
  width: 100%;
}
