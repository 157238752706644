.modal {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
}
  
/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 28px;
  text-align: center;
  font-size: 16px;
  color: gray;
}

.modal-content button {
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: white;
}

@media (max-width: 640px) {
  .modal-content{
    max-width: 300px;
    font-size: 14px;
  }
}

