@keyframes customKeyFrame {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.2)
  }
  100% {
    transform: scale(1)
  }
}

.LoadingContainer {
  margin: auto;
  opacity: 0.1;
  color: #005683;
  animation: customKeyFrame 3s infinite;
}

.logo {
  width: 171px;
  height: 149px;
  margin-bottom: 32px;
}

@media (max-width: 640px) {
  .logo {
    width: 114px;
    height: 149px;
    margin-bottom: 16px;
  }
}
